

















































































import { Mixins, Component } from 'vue-mixin-decorator';
import AuthConnector from '@/connector/Auth.vue';
import ProfileConnector from '@/connector/Profile.vue';
// eslint-disable-next-line import/no-cycle
import creditTurnMixin from '@/mixins/creditTurnMixin';
// eslint-disable-next-line import/no-cycle
import transactionMixin from '@/mixins/transactionMixin';

@Component({
  components: {
    AuthConnector,
    ProfileConnector,
  },
})
// eslint-disable-next-line max-len
export default class WithdrawPage extends Mixins<creditTurnMixin & transactionMixin>(creditTurnMixin, transactionMixin) {
  public amount: number = 0;

  public getFormData(): any {
    return {
      amount: this.amount,
    };
  }

  public resetFormData() {
    this.amount = 0;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async withdrawRequest(balance, refreshCallback) {
    this.amount = balance;
    await this.submitWithdrawRequest(refreshCallback);
  }
}
